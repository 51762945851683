import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Range, getTrackBackground } from "react-range";
import axiosConfig from '../../axiosConfig';
import { Modal } from "react-bootstrap";
import { useLoadScript, Autocomplete, MarkerF, GoogleMap, Circle } from '@react-google-maps/api';

import GlobalContext from "../../context/GlobalContext";
import { useFilterSearch } from '../../context/FilterSearchContext';
import { alignContent, zIndex } from "styled-system";

const STEP = 1;
const MIN = 50;
const MAX = 180;

const CheckStyled = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2b3940 !important;
  font-size: 16px;
  color: inherit;
  &::before {
    content: "\f0c8";
    font-weight: 400;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    color: #7e8989;
    margin-right: 11px;
    margin-top: 2px;
  }
  &.active {
    color: #2b3940 !important;
    font-weight: 600;
    &::before {
      content: "\f14a";
      font-weight: 900;
      color: #00b074;
    }
  }
`;

const Check = ({ children, onClick, item, filterGroupKey }) => {
  const { selectedHrItems } = useFilterSearch();

  // Ensure selectedUserItems contains the right category and is an array
  const isActive =
    selectedHrItems[filterGroupKey] &&
    Array.isArray(selectedHrItems[filterGroupKey]) &&
    selectedHrItems[filterGroupKey].some(
      (selectedItem) => selectedItem.title === item.title
    );

  const handleClick = () => {
    onClick(); // Trigger the onClick passed from the parent
  };

  return (
    <CheckStyled
      className={`toggle-item ${isActive ? "active" : ""}`}
      onClick={handleClick}
    >
      {children}
    </CheckStyled>
  );
};

const libraries = ['places'];

const ProfileFilterSidebar = () => {

  const {
    hrFilterData = {},
    hrFilterDataAll = {},
    handleHrFilterSave = () => { },
    handleHrFilterClear = () => { },
    selectedHrItems = {},
    setSelectedHrItems = () => { },
    distanceInRadius = 0,
    setDistanceInRadius = () => { }
  } = useFilterSearch() || {};

  const { user, coordinates } = useContext(GlobalContext);

  const camelCaseToTitle = (camelCaseString) => {
    return camelCaseString
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleSelectHrItem = (category, value) => {

    if (category === "SortBy") {
      const currentSelectedItems = selectedHrItems[category] || [];

      if (currentSelectedItems.some(item => item.id === value.id)) {
        setSelectedHrItems({
          ...selectedHrItems,
          [category]: currentSelectedItems.filter(item => item.id !== value.id)
        });
      } else {
        setSelectedHrItems({
          ...selectedHrItems,
          [category]: [value]
        });
      }
    } else {

      const currentSelectedItems = selectedHrItems[category] || [];
      if (currentSelectedItems.some(item => item.id === value.id)) {
        setSelectedHrItems({
          ...selectedHrItems,
          [category]: currentSelectedItems.filter(item => item.id !== value.id)
        });
      } else {
        setSelectedHrItems({
          ...selectedHrItems,
          [category]: [...currentSelectedItems, value]
        });
      }
    }

  };

  useEffect(() => {
    console.log('These are the selected hr items:', selectedHrItems);
  }, [selectedHrItems])

  const [rangeValues, setRangeValues] = useState([70, 150]);

  const styles = ProfileFilterSidebarStyling();

  ///////////////////////////////////////////////// for map modal from here//////////////////
  const [isOpen, setIsOpen] = useState(false);

  // Google Map settings
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  // const DefaultCenter = {
  //   lat: (distanceInRadius && distanceInRadius.latitude) ? distanceInRadius.latitude : 10.850516,
  //   lng: (distanceInRadius && distanceInRadius.longitude) ? distanceInRadius.longitude : 76.271080,
  // };

  const [location, setLocation] = useState({});
  const [region, setRegion] = useState({});
  const [radius, setRadius] = useState(5);
  const [markerPosition, setMarkerPosition] = useState({});

  useEffect(() => {
    setLocation({
      lat: distanceInRadius?.latitude || coordinates?.latitude || 10.850516,
      lng: distanceInRadius?.longitude || coordinates?.longitude || 76.271080,
    });
    setRegion({
      lat: distanceInRadius?.latitude || coordinates?.latitude || 10.850516,
      lng: distanceInRadius?.longitude || coordinates?.longitude || 76.271080,
    });
    setRadius(distanceInRadius?.radius || 5);
    setMarkerPosition({
      lat: distanceInRadius?.latitude || coordinates?.latitude || 10.850516,
      lng: distanceInRadius?.longitude || coordinates?.latitude || 76.271080,
    });
  }, [distanceInRadius]);

  const [searchInput, setSearchInput] = useState(''); // State for search input field

  const [zoom, setZoom] = useState(10); // State for map zoom level
  const autocompleteRef = useRef(null); // Ref to store autocomplete instance

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDPP-ap1mjWcD9jdPNG1bL197V66Xsgx_o",
    libraries,
  });

  // Function to handle when map location is changed
  const onMapChange = (lat, lng) => {
    setLocation({ lat, lng });
    setMarkerPosition({ lat, lng });
  };

  // Function to handle when a place is selected via autocomplete
  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry) {
      const { lat, lng } = place.geometry.location;
      const latitude = lat();
      const longitude = lng();

      setLocation({ lat: latitude, lng: longitude });
      setRegion({ lat: latitude, lng: longitude });
      setMarkerPosition({ lat: latitude, lng: longitude });
      setZoom(15);
      setSearchInput(place.formatted_address); // Update search input with the full address
    } else {
      console.log("No geometry data for the selected place.");
    }
  };

  // Update radius handler
  const handleRadiusChange = (event) => {
    setRadius(Number(event.target.value)); // Update radius from input
  };

  const handleCloseModal = () => {
    console.log('handle close clicked');
    setIsOpen(false);
    setLocation({
      lat: distanceInRadius?.latitude || coordinates?.latitude || 10.850516,
      lng: distanceInRadius?.longitude || coordinates?.longitude || 76.271080,
    });
    setRegion({
      lat: distanceInRadius?.latitude || coordinates?.latitude || 10.850516,
      lng: distanceInRadius?.longitude || coordinates?.longitude || 76.271080,
    });
    setRadius(distanceInRadius?.radius || 5);
    setMarkerPosition({
      lat: distanceInRadius?.latitude || coordinates?.latitude || 10.850516,
      lng: distanceInRadius?.longitude || coordinates?.latitude || 76.271080,
    });
  };

  const handleMapSave = () => {
    setDistanceInRadius({
      latitude: location.lat,
      longitude: location.lng,
      radius: radius
    });
    setIsOpen(false);
  };

  const handleMapClear = () => {
    setDistanceInRadius({});
    setIsOpen(false);
  }


  /////////////////////////////// for map modal till here


  return (
    <div className="border border-mercury p-2 bg-white rounded">
      {/* <!-- Sidebar Start --> */}

      <div className="scrollable-container" style={{ height: '400px', overflowY: 'auto' }}>

        {Object.keys(hrFilterDataAll).map((filterGroupKey, filterGroupIndex) => {
          const filterGroup = hrFilterDataAll[filterGroupKey];

          return (
            <div key={filterGroupIndex}>
              <div className="widgets mb-11">
                <h4 className="font-size-6 font-weight-semibold mb-6">
                  {camelCaseToTitle(filterGroupKey)}
                </h4>
                <ul className="list-unstyled filter-check-list">
                  {/* Check if filterGroup is an array and map over it */}
                  {Array.isArray(filterGroup) && filterGroup.length > 0 ? (
                    filterGroup.map((item, index) => (
                      <li key={index} className="mb-2">
                        <Check
                          onClick={() => handleSelectHrItem(filterGroupKey, item)}
                          item={item}
                          filterGroupKey={filterGroupKey} // Pass the filter category
                        >
                          {item.title}
                        </Check>
                      </li>
                    ))
                  ) : (
                    <li>No data available</li>
                  )}
                </ul>
              </div>
            </div>
          );
        })}

        <div className="widgets mb-11">
          <button
            onClick={() => setIsOpen(true)}
            style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent', padding: 0, margin: 0 }}
            className={`font-size-6 ${distanceInRadius.latitude &&
              distanceInRadius.longitude &&
              distanceInRadius.radius
              ? 'text-dark font-weight-bold'
              : 'text-dark font-weight-semibold'
              }`}
          >
            <span
              className={`mr-4 ${distanceInRadius.latitude &&
                distanceInRadius.longitude &&
                distanceInRadius.radius
                ? 'fas fa-check-square text-success'
                : 'far fa-square text-dark'
                }`}
            ></span>
            <span>Distance In Radius</span>
          </button>
        </div>

      </div>

      <div className="w-100 d-flex justify-content-start mt-2 mb-5 border-top border-mercury">
        <button
          className="btn btn-green text-uppercase rounded-3 m-1"
          onClick={() => handleHrFilterSave()}
        >
          Apply Filter
        </button>
        <button
          className="btn btn-outline-mercury text-uppercase rounded-3  border border-danger m-1"
          onClick={() => handleHrFilterClear()}
        >
          <span className="text-danger">Clear Filter</span>
        </button>
      </div>

      <Modal
        show={isOpen}
        onHide={handleCloseModal}
        style={{ zIndex: 2000 }}
        size="xl"
        centered
      >
        <Modal.Body style={{ position: 'relative', zIndex: 2000, width: '100%' }}>
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
            onClick={handleCloseModal}
          >
            <i className="fas fa-times"></i>
          </button>

          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p>Select distance in radius:</p>
            </div>

            {loadError ? (
              <div>Error loading maps</div>
            ) : !isLoaded ? (
              <div>Loading maps...</div>
            ) : (<div>
              <Autocomplete
                onLoad={(autocomplete) => {
                  autocompleteRef.current = autocomplete;
                }}
                onPlaceChanged={handlePlaceChanged}
                style={{ position: 'relative', zIndex: 2000 }}
              >
                <input
                  type="text"
                  className="form-control"
                  name="titleSearch"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  id="titleSearch"
                  placeholder="Search location..."
                  style={{ zIndex: 2000, position: 'relative', marginBottom: '5px' }}
                />
              </Autocomplete>

              {/* Custom styles for suggestion container */}
              <style>{`.pac-container {z-index: 3000;}`}</style>

              <GoogleMap
                center={region}
                zoom={zoom}
                mapContainerStyle={mapContainerStyle}
                onClick={(e) => onMapChange(e.latLng.lat(), e.latLng.lng())}
              >
                <MarkerF position={markerPosition} />
                <Circle
                  center={markerPosition}
                  radius={radius * 1000}
                  options={{
                    fillColor: '#FF0000',
                    fillOpacity: 0.2,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.5,
                    strokeWeight: 1,
                  }}
                />
              </GoogleMap>
            </div>)}
          </div>

          <p>Set Radius (in kilometers):</p>
          <input
            type="range"
            className="form-control-range"
            min="1"
            max="100"
            value={radius}
            onChange={handleRadiusChange}
          />
          <p>Radius: {radius} km</p>

          <div className="row d-flex justify-content-end"> {/* Use justify-content-end */}
            <div className="col-lg-6 col-md-12 d-flex justify-content-end">
              <button
                className="btn btn-primary text-uppercase rounded-3 mr-1"
                onClick={handleMapSave}
              >
                <span className="text-white">Save</span>
              </button>
              <button
                className="btn btn-outline-mercury text-uppercase rounded-3 border border-danger ml-1"
                onClick={handleMapClear}
              >
                <span className="text-danger">Clear</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProfileFilterSidebar;

function ProfileFilterSidebarStyling() {
  const styles = {

  };

  return styles;
}



